import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect:'/home'
  },
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue"),
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: [{
          title: "首页",
          header: false,
        }],
        // redirect: '/',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
      },
      {
        path: '/news',
        name: 'news',
        meta: [{
          title: "行业动态",
          header: false,
        }],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
      },
      {
        path: '/newsDetail',
        name: 'newsDetail',
        meta: [{
          title: "动态详情",
          header: false,
        }],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "newsDetail" */ '../views/newsDetail.vue')
      },
      {
        path: '/coreProducts',
        name: 'coreProducts',
        meta: [{
          title: "核心产品",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "coreProducts" */ '../views/coreProducts.vue')
      },
      {
        path: '/ecology',
        name: 'ecology',
        meta: [{
          title: "合作生态",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "ecology" */ '../views/ecology.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        meta: [{
          title: "联系我们",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: [{
          title: "企业概况",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "contact" */ '../views/companyProfile.vue')
      },
      {
        path: '/logistics',
        name: 'logistics',
        meta: [{
          title: "物流",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "contact" */ '../views/logistics.vue')
      },
      {
        path: '/storage',
        name: 'storage',
        meta: [{
          title: "仓储",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "contact" */ '../views/storage.vue')
      },
      {
        path: '/finance',
        name: 'finance',
        meta: [{
          title: "金融",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "contact" */ '../views/finance.vue')
      },
      {
        path: '/park',
        name: 'Park',
        meta: [{
          title: "园区",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "contact" */ '../views/park.vue')
      },
      {
        path: '/process',
        name: 'Process',
        meta: [{
          title: "加工",
          header: false,
        }],
        component: () => import(/* webpackChunkName: "contact" */ '../views/process.vue')
      }
    ]
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  let title = to.meta[0].title
  if (title) {
    // document.title = title
    next()
  }
})
export default router
