import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
 
// element组件按需加载
import '@/plugins/element.js'
// 引入公共样式
import '@/assets/css/common.css'

import 'animate.css'//导入animate.css
import wow from 'wowjs'//全局导入wow.js
Vue.prototype.$wow = wow //全局注册到Vue实例中

// 跳转页面后到顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

Vue.config.productionTip = false
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'Q8eV4jxXwOVEG7OvxqEKyvKdbpInKUxQ'
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
