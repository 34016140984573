// element 组件按需引用
import Vue from 'vue'
import {
  Message,
  Carousel,
  CarouselItem,
  Pagination
} from 'element-ui'
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.prototype.$message = Message