<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
    created(){
      window.addEventListener('resize', this.windowSize)
    },
    mounted(){
      this.windowSize();
    },
    methods:{
      windowSize(){
        var ratioX = document.documentElement.clientWidth / 1920;
        // var ratioY = document.documentElement.clientHeight /1080;
        document.querySelector('body').setAttribute('style', 'transform:scale('+ratioX+');transform-origin: left top;background-size: 100% 100%;')
      },
    }
}
</script>
<style lang="less">
.module_title{
  width:1920px;
  font-size:28px;
  margin:0 auto;
  text-align:center;
  font-family: MicrosoftYaHei;
  color: #2C2E31;
  margin-top:60px;
  margin-bottom:70px;
  clear:both;
  .line{
    width: 36px;
    padding-bottom:10px;
    margin:0 auto;
    border-bottom:3px solid #E73921;
  }
}
.head{
  width:1920px;
  height:70px;
  position:absolute;
  z-index:100;
  // 出现滚动条时，背景图铺完
  min-width: max-content;
  min-width: -moz-max-content;
}
.auto_box{
  width:1920px;
}
@keyframes banner-animation {
  0% {
      transform: translatey(150px);
  }
  100% {
      transform: translatey(0);
  }
}
</style>
